import { useApi } from '~/composables/useApi';
import { readonly, ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { PaymentResolveInterface } from '~/modules/GraphQL/types';

interface UsePaymentResolveInterface {

  /** Indicates whether any of the composable methods is in progress. */
  loading: Readonly<Ref<boolean>>;

  resolvePayment(payment_intent_id: string): Promise<PaymentResolveInterface | null>;
}

export const usePaymentResolve = (): UsePaymentResolveInterface => {
  const { mutate } = useApi();
  const loading = ref(false);
  const paymentInfo = ref<PaymentResolveInterface | null>(null);

  const resolvePayment = async (payment_intent_id: string): Promise<PaymentResolveInterface | null> => {
    const query = `
      mutation HandleCallbackPayment {
        HandleCallbackPayment(payment_intent_id: "${payment_intent_id}") {
          order_increment_id
          success
        }
      }
    `;

    try {
      loading.value = true;
      const response = await mutate(query);

      // @ts-ignore
      if (response && response.data && response.data.HandleCallbackPayment) {
        // @ts-ignore
        paymentInfo.value = response.data.HandleCallbackPayment;
      } else {
        paymentInfo.value = null;
      }

    } catch (err) {
      console.error("Error: ", err);
      paymentInfo.value = null;
    } finally {
      loading.value = false;
    }

    return paymentInfo.value;
  };

  return {
    resolvePayment,
    loading: readonly(loading),
  };
};

export default usePaymentResolve;
