<template>
  <div
    class="sf-header"
    :class="{
      'is-sticky': sticky,
      'is-hidden': hidden,
      'is-desktop-menu-open': isDesktopMenuOpen
  }">
    <div class="sf-header__wrapper">
      <div ref="header" class="sf-header__header">
        <div class="sf-header__container">
          <div class="sf-header__container-bottom grid">
            <div class="sf-header__container-bottom-left col-m-5">
              <SfButton
                class="sf-header__menu-switcher sf-button--pure desktop-only"
                aria-label="menu switcher"
                @click="toggleDesktopMenu"
              >
                <SfIcon
                  class="sf-header__menu-switcher-icon"
                  :icon="isDesktopMenuOpen? 'header_menu_closer' : 'header_menu_opener'"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                />
              </SfButton>
              <StoreSwitcher
                class="desktop-only"
              />
              <CurrencySelector
                class="desktop-only"
                v-if="hasCurrencyToSelect"
              />
              <slot name="search" v-bind="{ searchValue, searchPlaceholder }">
                <SfSearchBar
                  :value="searchValue"
                  :placeholder="searchPlaceholder"
                  aria-label="Search"
                  class="sf-header__search"
                  @input="$emit('change:search', $event)"
                  @keyup.enter="$emit('enter:search', $event)"
                />
              </slot>
            </div>
            <MobileNavigationItem
              class="top-navigation-menu mobile-only"
              :class="storePhone ? 'col-3' : 'col-2'"
              @click="toggleMobileMenu"
            >
              <template #icon>
                <SfIcon
                  :icon="isMobileMenuOpen ? 'header_menu_closer' : 'header_menu_opener'"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                  size="1.5rem"
                />
              </template>
            </MobileNavigationItem>
            <div
              class="sf-header__logo-wrapper col-m-2"
              :class="storePhone ? 'col-6' : 'col-8'">
              <slot name="logo" v-bind="{ logo, title }">
                <SfImage
                  v-if="logo"
                  :src="logo"
                  :alt="title"
                  :width="logoWidth"
                  :height="logoHeight"
                  class="sf-header__logo"
                  @click="handleHomeClick"
                />
                <h1 v-else class="sf-header__title">{{ title }}</h1>
              </slot>
            </div>
            <MobileNavigationItem
              class="top-navigation-account mobile-only col-1"
              data-testid="account"
              @click="$router.push(localePath('/customer'))"
            >
              <template #icon>
                <SfIcon
                  icon="header_account"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                  size="1rem"
                />
              </template>
            </MobileNavigationItem>
            <MobileNavigationItem
              v-if="storePhone"
              class="top-navigation-phone mobile-only col-1"
              data-testid="phone"
            >
              <template #icon>
                <SfLink
                  class="phone-link"
                  aria-label="phone link"
                  :link="`tel:+${storePhone.replace(/[^0-9]/g, '')}`"
                >
                  <SfIcon
                    icon="phone"
                    :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                    size="1rem"
                  />
                </SfLink>
              </template>
            </MobileNavigationItem>
            <MobileNavigationItem
              class="top-navigation-cart mobile-only col-1 mobile-cart-icon"
              :label="$t('')"
              data-testid="bottom-navigation-cart"
              @click="goToCart"
            >
              <template #icon>
                <SfIcon
                  icon="header_cart"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                  size="1rem"
                  :label="$t('Cart')"
                />
                <SfBadge
                  v-if="cartTotalItems"
                  class="sf-badge--number cart-badge"
                >
                  {{ cartTotalItems }}
                </SfBadge>
              </template>
            </MobileNavigationItem>
            <div class="sf-header__actions col-12 col-m-5">
               <div
                v-if="isMe"
                class="additional">
                <a
                  class="link"
                  aria-label="website link"
                  href="https://custom.togas.com/"
                  target="_blank"
                  :style="isHeaderTransparent
                    ? 'color: var(--togas-white-color)'
                    : 'color: var(--togas-black-color)'"
                  v-text="'ATELIER COUTURE'"
                />
              </div>

              <div
                v-if="storePhone"
                class="additional"
              >
                <a
                  class="link"
                  aria-label="phone link"
                  :href="`tel:+${storePhone.replace(/[^0-9]/g, '')}`"
                  :style="isHeaderTransparent
                    ? 'color: var(--togas-white-color)'
                    : 'color: var(--togas-black-color)'"
                  v-text="storePhone"
                />
              </div>

              <SfButton
                class="sf-header__action-search sf-button--pure sf-header__action desktop-only"
                data-testid="searchIcon"
                aria-label="Search"
                @click="$emit('is-search-open', true)"
              >
                <SfIcon
                  class="sf-header__icon"
                  icon="header_search"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                  :label="$t('Search')"
                />
              </SfButton>



              <slot
                name="header-icons"
                v-bind="{
                  activeIcon,
                  cartHasProducts,
                  cartItemsQty,
                  cartIcon,
                  wishlistIcon,
                  accountIcon,
                }"
              >
                <div class="sf-header__icons">
                  <SfButton
                    :class="{ 'display-none': !accountIcon }"
                    class="sf-button--pure sf-header__action"
                    data-testid="accountIcon"
                    aria-label="Account"
                    @click="$emit('click:account')"
                  >
                    <SfIcon
                      :icon="accountIcon"
                      size="1.25rem"
                      :class="{ 'sf-header__icon is-active': activeIcon === 'account' }"
                    />
                  </SfButton>
                  <SfButton
                    :class="{ 'display-none': !accountIcon }"
                    class="sf-button--pure sf-header__action"
                    data-testid="accountIcon"
                    aria-label="Account"
                    @click="$emit('click:account')"
                  >
                    <SfIcon
                      :icon="accountIcon"
                      size="1.25rem"
                      :class="{
                    'sf-header__icon is-active': activeIcon === 'account',
                  }"
                    />
                  </SfButton>
                  <SfButton
                    :class="{ 'display-none': !cartIcon }"
                    class="sf-button--pure sf-header__action"
                    data-testid="cartIcon"
                    aria-label="Cart"
                    @click="$emit('click:cart')"
                  >
                    <SfIcon
                      class="sf-header__icon"
                      :icon="cartIcon"
                      :has-badge="cartHasProducts"
                      :badge-label="cartItemsQty"
                      size="1.25rem"
                      :class="{'sf-header__icon is-active': activeIcon === 'cart'}"
                    />
                  </SfButton>
                </div>
              </slot>
            </div>
          </div>
        </div>
        <div
          class="sf-header__navigation"
          v-if="isDesktopMenuOpen"
          v-click-outside="toggleDesktopMenu"
        >
          <slot name="navigation" />
        </div>
      </div>
    </div>
    <MobileCategorySidebar
      :category-tree="categoryTree"
      v-if="isMobileMenuOpen"
      @is-search-open="$emit('is-search-open')"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import SfHeaderNavigationItem
  from '~/components/organisms/SfHeader/_internal/SfHeaderNavigationItem.vue';
import SfHeaderNavigation from '~/components/organisms/SfHeader/_internal/SfHeaderNavigation.vue';

Vue.component('SfHeaderNavigation', SfHeaderNavigation);
Vue.component('SfHeaderNavigationItem', SfHeaderNavigationItem);
import { isClient } from '~/utilities/helpers';
import { clickOutside } from '~/utilities/directives';

import { SfImage, SfSearchBar, SfButton, SfIcon, SfLink, SfBadge } from '~/components';

import { useTopBar } from '~/components/TopBar/useTopBar';
import { useUiState, useUser, useCart, useWebsiteCode } from '~/composables';
import { useConfigStore } from '~/stores/config';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { computed, useContext, useRouter, onMounted } from '@nuxtjs/composition-api';

export default {
  name: 'Header',
  directives: {clickOutside},
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    SfIcon,
    SfLink,
    SfBadge,
    MobileCategorySidebar: () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'),
    MobileNavigationItem: () => import('~/components/organisms/MobileNavigation/MobileNavigationItem.vue'),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
  },
  emit: ['is-search-open'],
  setup() {
    const {
      hasCurrencyToSelect,
      hasStoresToSelect
    } = useTopBar();

    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleDesktopMenu,
      toggleMobileMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen,
    } = useUiState();

    const {isMe, isInt} = useWebsiteCode();

    const {isAuthenticated} = useUser();
    const {app} = useContext();
    const {cart} = useCart();
    const router = useRouter();

    const storePhone = computed(() => useConfigStore().storeConfig.store_information?.phone);

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({name: 'home'});
      await router.push(homePath);
      if(isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const categories = useCategoryStore();

    onMounted(async () => {
      if(categories.categories === null) {
        await categories.load();
      }
    });

    const goToCart = async () => {
      await router.push(app.localeRoute({name: 'cart'}));
    };

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      isAuthenticated,
      toggleDesktopMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      handleHomeClick,
      goToCart,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      storePhone,
      isMe, isInt
    };
  },
  props: {
    categoryTree: {
      default: () => [],
    },
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: [Number, String],
      default: 35,
    },
    logoWidth: {
      type: [Number, String],
      default: 34,
    },
    title: {
      type: String,
      default: '',
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: 'empty_cart',
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: 'profile',
    },
    activeIcon: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'account', 'wishlist', 'cart'].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    searchValue: {
      type: String,
      default: '',
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: '0',
    },
    cartItemsQty: {
      type: [String, Number],
      default: '0',
    },
    isSticky: {
      type: Boolean,
      default: true,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
    isHeaderTransparent: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },
  computed: {
    cartHasProducts() {
      return parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return parseInt(this.wishlistItemsQty, 10) > 0;
    },
  },
  watch: {
    scrollDirection: {
      handler() {
        if(!isClient) return;
        window.cancelAnimationFrame(this.animationLong);
        this.animationLong = null;
        this.animationStart = null;
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
      },
    },
    isSticky: {
      handler(isSticky) {
        if(!isClient) return;
        this.sticky = isSticky;
      },
      immediate: true,
    },
  },
  mounted() {
    if(this.isSticky) {
      window.addEventListener('scroll', this.scrollHandler, {passive: true});
    }
  },
  beforeDestroy() {
    if(this.isSticky) {
      window.removeEventListener('scroll', this.scrollHandler, {
        passive: true,
      });
    }
  },
  methods: {
    animationHandler(timestamp) {
      if(!this.animationStart) this.animationStart = timestamp;
      const progress = timestamp - this.animationStart;
      if(progress < this.animationDuration) {
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
        return;
      }
      this.hidden = this.scrollDirection === 'down';
    },
    scrollHandler() {
      if(!isClient) return;
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if(!!this.refs) {
        if(currentScrollPosition >= this.$refs.header.offsetHeight) {
          this.scrollDirection =
            currentScrollPosition < this.lastScrollPosition ? 'up' : 'down';
        }
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfHeader.scss";
</style>
